.Servicii {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Serviciu {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  background-color: $gray-dark;
  color: #fff;
  padding: 4rem 2.5rem 2.5rem;
  margin-bottom: 2rem;
  border-radius: 10px;

  @include media-breakpoint-up(sm) {
    flex-basis: 48.5%
  }

  @include media-breakpoint-up(lg) {
    flex-basis: 31.5%;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-warning;
    border-radius: 50%;
    width: 185px;
    height: 185px;
    margin: 0 auto 2rem;

    svg {
      height: 80px;
    }
  }

  &__text { flex: 1; }
}
