.btn {
  &-wide {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  &-bebas {
    font-family: 'BebasNeueRegular';
    padding-top: .9rem;
    padding-bottom: .6rem;
  }

  &-outline {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    border-width: 2px;
    font-family: 'BebasNeueRegular';
    padding-top: .9rem;
    padding-bottom: .6rem;
    transition: all 0.3s;

    &-warning {
      border-color: $brand-warning;

      &-white {
        color: #fff;
      }

      &:hover {
        color: inherit;
        background-color: $brand-warning;
      }
    }
  }
}
