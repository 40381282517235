.BgSrc {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  &:not(.BgSrc--static) {
    background-attachment: fixed;
  }

  transition: opacity 0.3s ease-out;
  opacity: 0;

  &__loaded{
    opacity: 1;
  }
}
