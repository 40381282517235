$gray-dark:                 #111 !default;

$brand-primary:             #005895 !default;
$brand-info:                #4c96ca !default;
$brand-warning:             #ff781d !default;
$brand-warning-light:       #ff9c5a !default;

$font-family-sans-serif:    'Open Sans', sans-serif !default;

$headings-font-weight:      normal !default;
$headings-font-family:      BebasNeueRegular;

$heading-height-xs:         51px;
$heading-height-sm:         52px;
$heading-height-md:         81px;
$heading-height-lg:         63px;

$link-color:                $gray-dark !default;
$link-hover-color:          $brand-warning !default;
$link-hover-decoration:     none !default;

$grid-breakpoints: (
  xs: 0,
  sm: 544px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
) !default;

$container-max-widths: (
  sm: 576px,
  md: 720px,
  lg: 940px,
  xl: 1140px,
  xxl: 1360px,
) !default;

$input-color:                    #fff !default;
$input-border-color:             #fff !default;
$input-color-placeholder:        #fff !default;
