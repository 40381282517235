body {
  padding-top: $heading-height-xs;

  @include media-breakpoint-up(sm) {
    padding-top: $heading-height-sm;
  }

  @include media-breakpoint-up(md) {
    padding-top: $heading-height-md;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $heading-height-lg;
  }
}

hr {
  border: 2px solid $brand-warning;

  &.blue-with-arrow {
    position: relative;
    border: 1px solid $brand-primary;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border-style: solid;
      border-width: 15px;
      height: 0;
      width: 0;
      left: 50%;
      margin-left: -15px;
    }

    &:before {
      border-color: $brand-primary transparent transparent transparent;
      bottom: -31px;
    }

    &:after {
      border-color: #fff transparent transparent transparent;
      bottom: -28px;
    }
  }
}

.sageata-top {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    position: absolute;
    bottom: 50px;
    left: 50%;
    margin-left: -10px;
    vertical-align: top;
    height: 40px;
  }
}
