.Footer, .Copy {
  background-color: $brand-primary;
  color: #fff;
  font-size: 14px;
}

.Footer {
  &__contact {
    line-height: 1.25;

    svg {
      vertical-align: top;
      height: 1.25rem;
      width: auto;
      margin-right: .625rem;
      margin-bottom: 1rem;
    }
  }

  &__arrows {
    svg {
      width: 1.25rem;
      height: auto;
      vertical-align: top;
    }
  }
}

.Truck {
  svg {
    height: 50px;
    vertical-align: top;
  }
}

.Copy {
  font-family: 'BebasNeueRegular';
}
