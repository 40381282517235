.Cover {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - #{$heading-height-xs});
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include media-breakpoint-up(md) {
    min-height: calc(100vh - #{$heading-height-md});
  }

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - #{$heading-height-lg});
  }

  @include media-breakpoint-up(xxl) {
    &:not(.Cover--home) {
      min-height: calc(75vh - #{$heading-height-lg});
    }
  }
}

.CMS {
  &-text {
    font-family: 'BebasNeueRegular';
    font-size: 50px;
    text-align: center;
    padding-bottom: .5rem;
    border-bottom: 2px solid #fff;

    @include media-breakpoint-up(md) {
      padding-left: 10rem;
      padding-right: 10rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 85px;
    }
  }

  @include media-breakpoint-down(sm) {
    &-part { display: block; }
    &-cms { font-size: 135px; line-height: .75; }
    &-universal { font-size: 53px; }
    &-transport { font-size: 50px; }
  }
}

.Oriunde {
  @include media-breakpoint-up(xxl) {
    font-size: 30px;
  }
}
