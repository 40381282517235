.Header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background-color: #fff;
  box-shadow: 0 1px 15px rgba($gray-light, .75);

  @include media-breakpoint-up(lg) {
    background: linear-gradient(
      to right,
      $brand-warning 0%,
      $brand-warning 50%,
      $brand-warning 50%,
      #fff 50%,
      #fff 100%
    );
  }

  &__logo {
    margin-top: 10px;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      text-align: center;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    svg {
      height: 30px;
      vertical-align: middle;
    }
  }

  &__menu {
    position: relative;
    padding: 0;
    background-color: #fff;
    list-style: none;
    font-family: BebasNeueRegular;
    text-align: center;

    &.is-open {
      display: block !important;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    @include media-breakpoint-up(md) {
      text-align: right;
    }

    @include media-breakpoint-up(lg) {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC8AAAA/CAMAAABHL2EFAAAAGFBMVEXw8PARERH/eiGlpaVsTDc2KyTBXx3///+08Z/8AAAACHRSTlP/////////AN6DvVkAAADNSURBVHjahdVJCsMwFAVBOx50/xsnux/Ri9K6aLB4wscH5z7/zy3/bvw55J/NX2vzzscjH498PPLxyI93/sfHO3/Tn8mP9xKW/JOP3bzy8rnLeOTjkR/P/LXg39x9vPLjPbR47Dge+Xjl652vd76+zyQe+Xjk4zW0eu+43vn65uOZr3e+3vn6PhP4DA2++Xrk4zG0eOTjkY9HPl75eufr+y+EzyuEP50f7x2Px47jkY9HPh75eOTj8UzisYR45OOTh8+O4ZOHTx6e+f18AUF6KIC1RdzvAAAAAElFTkSuQmCC') no-repeat;
        width: 49px;
        height: $heading-height-lg;
      }
    }
  }

  &__item {
    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }

  &__link {
    position: relative;
    display: block;
    margin: 0 0.75rem;
    padding: .8125rem .7rem;

    @include media-breakpoint-up(lg) {
      border-top: 0.4375rem solid transparent;
      padding: 1rem;
    }

    &--active {
      color: $brand-warning;

      @include media-breakpoint-up(lg) {
        border-top-color: $brand-warning;

        &:before,
        &:after {
          content: "";
          position: absolute;
          top: -0.4375rem;
          width: 0;
          height: 0;
          border-style: solid;
        }

        &:before {
          left: -0.4375rem;
          border-width: 0 0.4375rem 0.4375rem 0;
          border-color: transparent $brand-warning transparent transparent;
        }

        &:after {
          right: -0.4375rem;
          border-width: 0.4375rem 0.4375rem 0 0;
          border-color: $brand-warning transparent transparent transparent;
        }
      }
    }
  }
}

.LangBar {
  display: flex;
  justify-content: center;
  background-color: #fff;
  line-height: 1;
  margin-top: 5px;
  margin-bottom: 10px;
  text-align: center;

  @include media-breakpoint-down(sm) {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 50%;
    margin-left: -51px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 26px;
  }

  &__lang {
    position: relative;

    &:not(:last-child) {
      margin-right: 5px;
    }

    &.active {
      &:before {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 50%;
        margin-left: -5px;

        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent $brand-warning transparent;
      }
    }
  }

  svg {
    height: 14px;
    vertical-align: top;
  }
}
