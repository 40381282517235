.row {
  &-eq-height {
    display: flex;

    > [class^=col] {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-down(sm) {
        display: block;
      }
    }

    > .col-flex-middle {
      justify-content: center;
    }

    > .col-flex-end {
      justify-content: flex-end;
    }

    .btn { align-self: flex-start; }

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }
}
