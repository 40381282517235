@font-face {
  font-family: 'BebasNeueRegular';
  src: url('/font/BebasNeue-webfont.eot');
  src: url('/font/BebasNeue-webfont.eot?#iefix') format('embedded-opentype'),
       url('/font/BebasNeue-webfont.woff') format('woff'),
       url('/font/BebasNeue-webfont.ttf') format('truetype'),
       url('/font/BebasNeue-webfont.svg#BebasNeueRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
