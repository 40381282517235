.Map {
  background: linear-gradient(135deg, #3f3f3f 0%, #171717 100%);

  .Cover {
    justify-content: space-between;
  }

  &__transport {
    @include media-breakpoint-up(md) {
      align-self: flex-start;
      position: relative;
      top: 150px;
      left: 60px;
    }

    @include media-breakpoint-up(lg) {
      top: 150px;
      left: 70px;
    }

    @include media-breakpoint-up(xl) {
      top: 150px;
      left: 90px;
    }
  }

  &__barna {
    display: none;
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      display: block;
    }

    &--orange {
      background-color: $brand-warning;
      height: 3px;
      width: 70%;
    }

    &--blue {
      background-color: $brand-primary;
      height: 15px;
      width: 75%;
    }

    &--unu,
    &--doi {
      background-color: #fff;
      height: 10px;
    }

    &--unu { width: 25%; }
    &--doi { width: 18%; }
  }

  &__text {
    padding: 25px 50px 20px;
    background-color: $gray-dark;
    color: #fff;
    font-family: 'BebasNeueRegular';
    font-size: 18px;

    @include media-breakpoint-up(md) {
      padding: 0.78125em 1.5625em 0.625em;
      background-color: rgba(#fff, .85);
      color: $gray-dark;
      font-size: 24px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 32px;
    }
  }
}
