.list {
  &-inline {
    &-item {
      @include media-breakpoint-up(sm) {
        &:not(:last-child) {
          margin-right: 2rem;
        }
      }

      @include media-breakpoint-down(sm) {
        display: block;
        text-align: center;

        &:not(:last-child) {
          margin-bottom: .5rem;
        }
      }
    }
  }
}
