.Section {
  position: relative;

  &__spacing {
    padding-top: 50px;
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
      &--narrow {
        padding-top: 20px;
        padding-bottom: 20px;
      }

      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  &--top-border {
    border-top: 1px solid;
  }

  &__title {
    &__wrapper {
      @include media-breakpoint-up(sm) {
        position: absolute;
      }

      top: -3rem;
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }

    &__text {
      position: relative;
      font-size: 1.5rem;
      font-family: BebasNeueRegular;
      background-color: #fff;
      padding: 1rem 2rem .5rem;
      min-width: 12.5rem;
      text-align: center;

      &:before,
      &:after {
        content:"";
        width: 0;
        height: 0;
        border-style: solid;
        position: absolute;
        z-index: 1;
        top: 0;
      }

      &:before {
        left: -2rem;
        border-width: 0 0 3rem 2rem;
        border-color: transparent transparent #fff transparent;
      }

      &:after {
        right: -2rem;
        border-width: 3rem 0 0 2rem;
        border-color: transparent transparent transparent #fff;
      }

      &--alt {
        background-color: $gray-dark;
        color: #fff;

        &:before {
          border-color: transparent transparent $gray-dark transparent;
        }

        &:after {
          border-color: transparent transparent transparent $gray-dark;
        }
      }
    }
  }
}
