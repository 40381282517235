.Ruta {
  font-family: 'BebasNeueRegular';
  color: #fff;
  background-color: $brand-primary;
  text-align: center;
  padding-top: .7rem;
  padding-bottom: .4rem;

  &:nth-child(2n+2) {
    background-color: $brand-info;
  }

  &--int {
    background-color: $brand-warning;

    &:nth-child(2n+2) {
      background-color: $brand-warning-light;
    }
  }
}
