.Contact {
  background: linear-gradient(135deg, #f59336 0%, #fce5c9 100%);

  .btn {
    font-family: 'BebasNeueRegular';
    padding-top: .75rem;
    padding-bottom: .55rem;
  }

  .form-control {
    border-width: 2px;
    background: transparent;

    &::placeholder { text-transform: uppercase; }
  }
}

#map {
  height:400px;
  width:100%;
}
.gm-style-iw * {
  display: block;
  width: 100%;
}
.gm-style-iw h4, .gm-style-iw p {
  margin: 0;
  padding: 0;
}
.gm-style-iw a {
  color: #4272db;
}
