.text {
  &-center { text-align: center; }
  &-bold { font-weight: bold; }
  &-right { text-align: right; }
  &-gray { color: $gray-light; }
  &-white { color: #fff; }
  &-10 { font-size: 10px; }
  &-12 { font-size: 12px; }
  &-14 { font-size: 14px; }
  &-20 { font-size: 20px; }
  &-bebas {
    letter-spacing: 1px;
    line-height: 1;
  }
  &-vandy { color: #f02954; }
}

.p-t {
  &-0-5 { padding-top: .5rem; }
}

.b-b-2 {
  border-bottom: 2px solid;
}

.b-t-2 {
  border-top: 2px solid;
}
