@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";
@import "utilities";
@import "fonts";
@import "global";
@import "grid";
@import "buttons";
@import "lists";
@import "forms";

@import "Components/Section";
@import "Components/Header";
@import "Components/Burger";
@import "Components/BgSrc";
@import "Components/Cover";
@import "Components/SigurantaSporita";
@import "Components/Map";
@import "Components/Servicii";
@import "Components/Rute";
@import "Components/Contact";
@import "Components/Footer";
